import * as React from 'react';

import Callout from '../components/v2/callout';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Footer from '../components/v2/footer';
import Header from '../components/v2/header';
import Section from '../components/section';
import { faTelescope } from '@fortawesome/pro-regular-svg-icons';

const NotFoundPage = () => {
  return (
    <>
      <Header btnTheme="primary" />
      <Section
        theme="default"
        style={{ padding: '8rem 0', marginTop: `4.5rem`, minHeight: '60vh' }}>
        <div className="row justify-content-center">
          <div className="col-12 col-lg-8 text-center">
            <p className="heading-xl color-muted-foreground">
              <FontAwesomeIcon icon={faTelescope} />
            </p>
            <h1 className="heading-xl color-black">Oops, page not found.</h1>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-12 col-lg-6 text-center">
            <h2 className="text-body color-muted-foreground text-center">
              The page you are looking for does not exist. Contact us if you need help.
            </h2>
          </div>
        </div>
      </Section>
      <Callout />
      <Footer />
    </>
  );
};

export default NotFoundPage;
